import {
    linkItemStyles,
    smallHeadingStyles,
    titleLinkStyles,
} from "@components/layouts/navigation/styles";
import { Text } from "@ui/components/content/text/Text";
import { List, ListItem } from "@ui/components/data-display/list/List";
import { Box } from "@ui/components/layout/box/Box";
import { isEmpty } from "lodash-es";
import type { FC } from "react";
import { NextLink } from "@ui/components/navigation/link/NextLink";

interface IDesktopSubNavItemProps {
    title: any;
    titleLink?: any;
    links: any;
}

export const DesktopSubNavItem: FC<IDesktopSubNavItemProps> = ({ title, titleLink, links }) => {
    if (titleLink?.fields) {
        titleLink.fields.label = title;
    }

    const titleLinkHref = () => {
        if (titleLink.fields?.internalLink) {
            return titleLink.fields?.internalLink?.fields?.completeSlug;
        }
        return titleLink.fields?.externalLink?.fields?.completeSlug;
    };
    const titleLinkLabel = () => {
        if (titleLink.fields?.internalLink) {
            return titleLink.fields?.internalLink?.fields?.label;
        }
        return titleLink.fields?.externalLink?.fields?.label;
    };
    const titleOutput = titleLink ? (
        <NextLink sx={titleLinkStyles} href={titleLinkHref()}>
            {titleLinkLabel()}
        </NextLink>
    ) : (
        <Text sx={smallHeadingStyles}>{title}</Text>
    );
    return (
        <Box mb={8} data-testid="desktopsubitem">
            {!isEmpty(links) && (
                <>
                    {titleOutput}
                    <List>
                        {links?.map((link) => {
                            const {
                                internalLink,
                                externalLink,
                                label: defaultLabel,
                                completeSlug,
                            } = link?.fields ?? {};
                            const label =
                                internalLink?.fields?.label ??
                                externalLink?.fields?.label ??
                                defaultLabel;
                            const href =
                                internalLink?.fields?.completeSlug ??
                                externalLink?.fields?.completeSlug ??
                                completeSlug;

                            return (
                                <ListItem key={link?.sys?.id} marginBottom={2}>
                                    <NextLink sx={linkItemStyles} href={href}>
                                        {label}
                                    </NextLink>
                                </ListItem>
                            );
                        })}
                    </List>
                </>
            )}
        </Box>
    );
};
