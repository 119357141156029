import React from "react";
import { NextImage } from "@ui/components/media-and-icons/image/NextImage";
import { flagStyle } from "./styles";

const CountryFlagWithName = ({ icon, name }: { icon: string; name: string }) => {
    return (
        <>
            <NextImage
                blurDataURL="/assets/flags/skeleton.webp"
                sx={flagStyle}
                src={icon}
                alt={name}
                sizes={["5vw"]}
                quality={10}
            />
            {name}
        </>
    );
};

export default CountryFlagWithName;
